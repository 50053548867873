import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  TYPE_MESSAGE = {
    SUCCESS_TYPE: 'success', //mat-green-snackbar
    ERROR_TYPE: 'error', //mat-red-snackbar
  };

  constructor(private snackBar?: MatSnackBar) { }

  showSnackBarMessage(message: string, type: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 5000 ,
      panelClass: [type === this.TYPE_MESSAGE.SUCCESS_TYPE ? 'mat-green-snackbar' : 'mat-red-snackbar']
    });
  }

  showAlertSnackBarMessage(message: string, type: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 5000 ,
      panelClass: [type === this.TYPE_MESSAGE.SUCCESS_TYPE ? 'mat-red-snackbar' : 'mat-green-snackbar']
    });
  }
}
