import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiEndpoints } from "../../../api-endpoints";


@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  HTTPOptions:Object = {
    observe: 'response',
    responseType: 'text'
 }

  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  constructor(private http: HttpClient) { }

  getPartnerListTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.PARTNER_URL;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getPartnerById(id:any): Observable<any>{
    let url = this.apiEndpoints.PARTNER_URL + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  partnerApproved(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.PARTNER_APPROVED_BASE_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }


  getMobileWalletListDropdown(): Observable<any> {
    let url = this.apiEndpoints.MOBILE_BANKING_DROPDOWN_URL;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }


  createParner(Obj: any): Observable<any>{
    let url = this.apiEndpoints.PARTNER_URL;
    return this.http.post<any>(url, Obj).pipe(map(value => value))
  }

  updatePartner(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.PARTNER_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }

  deletePartnerImage(id:any): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_ATTACHMENT_URL + '/' + id;
    return this.http.delete<any>(url,this.HTTPOptions).pipe(
      map(value => value)
      );
  }

  partnerStatusUpdate(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.PARTNER_STATUS_UPDATE_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }

  getDuePayment(id:any): Observable<any>{
    let url = this.apiEndpoints.PARTNER_PAYMENT_URL + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  createPayment(Obj: any): Observable<any>{
    let url = this.apiEndpoints.TRANSECTION_PAYMENT_URL;
    return this.http.post<any>(url, Obj).pipe(map(value => value))
  }

  getPartnerRatingData(id:any): Observable<any> {
    let url = this.apiEndpoints.PARTNER_RATING_URL + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  getAttachmentById(id:any): Observable<any>{
    let url = this.apiEndpoints.CUSTOMER_ATTACHEMNT_GET_LIST + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  createAttachment(Obj: any): Observable<any>{
    let url = this.apiEndpoints.CUSTOMER_ATTACHEMNT_CREATE;
    return this.http.post<any>(url, Obj,this.HTTPOptions).pipe(map(value => value))
  }

  getAttachmentTypeDropList(): Observable<any> {
    let url = this.apiEndpoints.PARTNER_UTILITY_URL + '/attachment-type/dropdown-list';
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  // search

  getPartnerDropdownList(): Observable<any> {
    let url = this.apiEndpoints.PARTNER_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

partnerSearch(page: number, size: number, mapData: Map<string, any>): Observable<any> {

  mapData.set("page", page)
  mapData.set("size", size)

  const params = this.getParams(mapData)

  let url = this.apiEndpoints.PARTNER_URL;
  const fullUrl = `${url}/search`;
  return this.http.get<any>(fullUrl, { params }).pipe(
    map(value => value)
  );
}


getParams(map: Map<string, any>): HttpParams {

  let params = new HttpParams()
  map.forEach((value: string, key: any) => {
    params = params.append(key, value.toString());
    console.log(key, value);
  });

  return params;
}

}
