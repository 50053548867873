import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiEndpoints } from '../../../api-endpoints';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  HTTPOptions:Object = {
    observe: 'response',
    responseType: 'text'
 }

 private apiEndpoints: ApiEndpoints = new ApiEndpoints();

  constructor(private http: HttpClient) { }

  getNotificationListData(): Observable<any> {
    let url = this.apiEndpoints.NOTIFICATION_LIST_URL;
    // const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  putNotificationListData(id:any): Observable<any>{
    let url = this.apiEndpoints.NOTIFICATION_LIST_URL + '/' + id;
    return this.http.put<any>(url,id).pipe(
      map(value => value)
    );
  }

}

