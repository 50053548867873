import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'bdtComma', pure: false})
export class BdtCommaPipe implements PipeTransform {
  banglaNumber: string[] = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯',];

  // englishNumber: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ];
  public transform(numberVal: number, isBengali: boolean = false): any {

    if (numberVal === undefined || numberVal === null) {
      return this.getCommaNumber(0, isBengali);
    }
    return this.getCommaNumber(numberVal, isBengali);
  }

  getCommaNumber(number, type) {
    const isNegative = number < 0;
    if (isNegative) {
      number = 0 - number;
    }
    const isFloat = !this.isInt(number);
    let strFloatNumberSplit;
    let numArray;
    if (isFloat) {
      const strFloatNumber = number + '';
      strFloatNumberSplit = strFloatNumber.split('.');
      number = parseInt(strFloatNumberSplit[0], 10);
      numArray = this.getCommaNumberArray(number, type);
    } else {
      numArray = this.getCommaNumberArray(number, type);
    }
    let numberString = '';

    for (let i = 0; i < numArray.length; i++) {
      numberString += numArray[i];
    }
    if (isFloat) {
      numberString += '.' + this.getBanglaString(strFloatNumberSplit[1]);
    } else {
      numberString += '.০০';
    }
    if (isNegative) {
      numberString = '-' + numberString;
    }
    return numberString;
  }

  isInt(n) {
    return n % 1 === 0;
  }

  getBanglaString(number) {
    const numStr = number + '';
    let numberString = '';

    for (let i = 0; i < numStr.length; i++) {
      numberString += this.getBangla(numStr[i]);
    }
    if (numberString.length < 2) {
      numberString += '০';
    }
    return numberString;
  }

  getCommaNumberArray(number, type) {
    let numberArray;

    if (type) {
      numberArray = this.getBanglaNumber(number);
    } else {
      numberArray = this.getNumberArray(number);
    }
    const numReverse = numberArray.reverse();
    const getCommaArray = this.setCommaToArray(numReverse);
    return getCommaArray.reverse();
  }

  setCommaToArray(numArray) {
    const commaArray = [];
    for (let i = 0; i < numArray.length; i++) {
      commaArray.push(numArray[i]);
      if (i === 2 && i !== numArray.length - 1) {
        commaArray.push(',');
      }
      if (i > 2 && i % 2 === 0 && i !== numArray.length - 1) {
        commaArray.push(',');
      }

    }
    return commaArray;
  }

  getNumberArray(number) {
    const numberSrt = number + '';
    const numArray = [];
    for (let i = 0; i < numberSrt.length; i++) {
      numArray.push(numberSrt[i]);
    }
    return numArray;
  }

  getBanglaNumber(number) {
    const banArray = [];
    const numArray = this.getNumberArray(number);
    for (let i = 0; i < numArray.length; i++) {
      const bnNum = this.getBangla(numArray[i]);
      banArray.push(bnNum);
    }
    return banArray;
  }

  getBangla(number) {
    return this.banglaNumber[number];
  }
}
