import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiEndpoints } from "../../../api-endpoints";


@Injectable({
  providedIn: 'root'
})
export class DealerService {

  HTTPOptions:Object = {
    observe: 'response',
    responseType: 'text'
 }

  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  constructor(private http: HttpClient) { }

  getDealerListTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.DEALER_URL;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }


  getDealerById(id:any): Observable<any>{
    let url = this.apiEndpoints.DEALER_URL + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  dealerApproved(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.Dealer_APPROVED_BASE_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }

  getCustomerDistrictDropList(): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_DISTRICT_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getCustomerThanaDropList(districtId:any): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_THANA_DROPDOWN_LIST + '/' + districtId;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getCustomerClusterDropList(thanaId:any): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_CLUSTER_DROPDOWN_LIST + '/' + thanaId;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  // getAttachmentTypeDropList(): Observable<any> {
  //   let url = this.apiEndpoints.ATTACHMENT_TYPE_DROPDOWN_LIST ;
  //   return this.http.get<any>(url).pipe(
  //     map(value => value)
  //   );
  // }

  getAttachmentTypeDropList(): Observable<any> {
    let url = this.apiEndpoints.DEALER_UTILITY_URL + '/attachment-type/dropdown-list';
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }


  getBrandDropDownList(){
    let url = this.apiEndpoints.BRAND_BASE_URL+this.apiEndpoints.DROPDOWN_LIST_API_URL;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  createDealer(Obj: any): Observable<any>{
    let url = this.apiEndpoints.DEALER_URL;
    return this.http.post<any>(url, Obj).pipe(map(value => value))
  }

  dealerUpdate(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.DEALER_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }

  deleteDealerImage(id:any): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_ATTACHMENT_URL + '/' + id;
    return this.http.delete<any>(url,this.HTTPOptions).pipe(
      map(value => value)
      );
  }

  dealerStatusUpdate(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.DEALER_STATUS_UPDATE_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }


  // file upload
  fileUpload(formData: any): Observable<any>{
    let url = this.apiEndpoints.FILE_UPLOAD_URL;
    return this.http.post<any>(url, formData).pipe(map(value => value))
  }

  // search

  getDealerDropdownList(): Observable<any> {
    let url = this.apiEndpoints.DEALER_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

dealerSearch(page: number, size: number, mapData: Map<string, any>): Observable<any> {

  mapData.set("page", page)
  mapData.set("size", size)

  const params = this.getParams(mapData)

  let url = this.apiEndpoints.DEALER_URL;
  const fullUrl = `${url}/search`;
  return this.http.get<any>(fullUrl, { params }).pipe(
    map(value => value)
  );
}


getParams(map: Map<string, any>): HttpParams {

  let params = new HttpParams()
  map.forEach((value: string, key: any) => {
    params = params.append(key, value.toString());
    console.log(key, value);
  });

  return params;
}



}
