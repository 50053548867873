
import * as uuid from 'uuid';
// import {
//   APPEND_END_POINT,
//   AUTOCOMPLETE_END_POINT,
//   CHANGE_END_POINT,
//   CREATE_ALL_END_POINT,
//   CREATE_END_POINT,
//   DELETE_ALL_END_POINT,
//   DELETE_END_POINT,
//   GET_ALL_END_POINT,
//   GET_BY_OID_END_POINT,
//   GET_END_POINT,
//   GET_LIST_END_POINT,
//   GET_SELECTED_END_POINT,
//   SAVE_URL,
//   SEARCH_END_POINT,
//   UPDATE_ALL_END_POINT,
//   UPDATE_END_POINT
// } from '../../config/constant/api';


import { IRequestBody, IOidHolderRequestBody, IRequestHeader } from '../../../../pages/shared/model/request/request';
import { IResponseData, IResponse, IResponseHeader } from '../../../../pages/shared/model/response/response';
import { RestClient } from '../../../../pages/shared/service/rest-client';

export abstract class MasterService<I extends IRequestBody & IResponseData> {

  protected constructor(public _restClient: RestClient,
                        protected _BASE_URL: string,
                        private _PATH: string,
                        private _CURRENT_VERSION: string) {
  }

  // preCreateAll(i: I[]): I[] {
  //   return i;
  // }

  // preCreate(i: I): I {
  //   return i;
  // }

  // preGetAll(i: I[]): I[] {
  //   return i;
  // }

  // preGetList(i: I[]): I[] {
  //   return i;
  // }

  // preGetSelected(data: string[]): string[] {
  //   return data;
  // }

  // preUpdateAll(i: I[]): I[] {
  //   return i;
  // }

  // preUpdate(i: I[]): I[] {
  //   i.forEach(it => {
  //     Object.keys(it).forEach(key => {
  //       if (it[key] === '') {
  //         it[key] = null;
  //       }
  //     });
  //   });
  //   return i;
  // }

  // preDelete(i: I[]): I[] {
  //   return i;
  // }

  // preDeleteAll(i: I[]): string[] {
  //   return i.map(val => {
  //     return val['nodeOid'];
  //   });
  //   // return i.map(val => val['nodeOid']);
  // }

  // preSearch(i: I[]): I[] {
  //   return i;
  // }

  // preAppend(i: I[]): I[] {
  //   return i;
  // }

  // get(body: IOidHolderRequestBody[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<IOidHolderRequestBody[]>(this.getUrl() + GET_END_POINT, GET_END_POINT, this._CURRENT_VERSION, body);
  //   return of();
  // }

  // getList(body: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + GET_LIST_END_POINT, GET_LIST_END_POINT, this._CURRENT_VERSION, this.preGetList(body));
  //   return of();
  // }

  // search(body: I[], meta?: Object): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + SEARCH_END_POINT, SEARCH_END_POINT, this._CURRENT_VERSION, this.preSearch(body), meta);
  //   return of();
  // }

  // autocomplete(body: I[], meta?: Object): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + AUTOCOMPLETE_END_POINT, AUTOCOMPLETE_END_POINT, this._CURRENT_VERSION, this.preSearch(body), meta);
  //   return of();
  // }

  // getAll(body: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + GET_ALL_END_POINT, GET_ALL_END_POINT, this._CURRENT_VERSION, this.preGetAll(body));
  //     return of();
  //   }

  // getSelected(data: string[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<string[]>(this.getUrl() + GET_SELECTED_END_POINT, GET_SELECTED_END_POINT, this._CURRENT_VERSION, this.preGetSelected(data));
  //   return of();
  // }

  // create(i: I): Observable<IResponse<I>> {
  //   // return this.sendRequest<I>(this.getUrl() + CREATE_END_POINT, CREATE_END_POINT, this._CURRENT_VERSION, this.preCreate(i));
  //   return of();
  // }

  // getByOid(body: I): Observable<IResponse<I>> {
  //   // return this.sendRequest<I>(this.getUrl() + GET_BY_OID_END_POINT, GET_BY_OID_END_POINT, this._CURRENT_VERSION, body);
  //   return of();
  // }


  // createAll(i: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + CREATE_ALL_END_POINT, CREATE_ALL_END_POINT, this._CURRENT_VERSION, this.preCreateAll(i));
  //   return of();
  // }

  // update(i: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + UPDATE_END_POINT, UPDATE_END_POINT, this._CURRENT_VERSION, this.preUpdate(i));
  //   return of();
  // }

  // change(i: I): Observable<IResponse<I>> {
  //   // return this.sendRequest<I>(this.getUrl() + CHANGE_END_POINT, CHANGE_END_POINT, this._CURRENT_VERSION, i);
  //   return of();
  // }

  // updateAll(i: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + UPDATE_ALL_END_POINT, UPDATE_ALL_END_POINT, this._CURRENT_VERSION, this.preUpdateAll(i));
  //     return of();
  // }

  // delete(i: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + DELETE_END_POINT, DELETE_END_POINT, this._CURRENT_VERSION, this.preDelete(i));
  //   return of();
  // }

  // deleteAll(i: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<string[]>(this.getUrl() + DELETE_ALL_END_POINT, DELETE_ALL_END_POINT, this._CURRENT_VERSION, this.preDeleteAll(i));
  //   return of();
  // }

  // append(i: I[]): Observable<IResponse<I>> {
  //   // return this.sendRequest<I[]>(this.getUrl() + APPEND_END_POINT, APPEND_END_POINT, this._CURRENT_VERSION, this.preAppend(i));
  //   return of();
  // }

  // save(i: I): Observable<IResponse<I>> {
  //   // this.sendRequest<I>(this.getUrl() + SAVE_URL, SAVE_URL, this._CURRENT_VERSION, i);
  //   return of();
  // }

  // getUrl(): string {
  //   return this._BASE_URL + this._PATH;
  // }

  // getCurrentVersion(): string {
  //   return this._CURRENT_VERSION;
  // }

  // getRestClient(): RestClient {
  //   return this._restClient;
  // }

  // protected sendRequest<B>
  // (url: string,
  //  sourceService: string,
  //  version: string,
  //  body: B,
  //  meta?: Object,
  //  hasDataNode: boolean = true): Observable<IResponse<I>> {
  //   if (body === null) {
  //     return of({
  //       header: this._createResponseHeader(),
  //       meta: null,
  //       body: {
  //         data: []
  //       }
  //     });
  //   }
  //   let requestBody;
  //   if (hasDataNode) {
  //     requestBody = {data: body};
  //   } else {
  //     requestBody = body[0];
  //   }
  //   return this._restClient.post(url,
  //     {
  //       header: this._createHeader(url, sourceService, version),
  //       meta: meta || {},
  //       body: requestBody
  //     });
  // }


  // protected _createHeader(type: string, sourceService: string, version: string): IRequestHeader {
  //   return {
  //     requestId: uuid.v4(),
  //     requestSource: 'portal',
  //     requestSourceService: sourceService,
  //     requestClient: 'grp',
  //     requestType: type,
  //     requestTime: new Date().toISOString(),
  //     requestVersion: version,
  //     requestTimeoutInSeconds: 30,
  //     requestRetryCount: 3
  //   };
  // }

  // protected _createResponseHeader(): IResponseHeader {
  //   return {
  //     requestReceivedTime: new Date(),
  //     responseTime: new Date(),
  //     hopCount: null,
  //     responseProcessingTimeInMs: null,
  //     requestId: null,
  //     responseCode: '400',
  //     responseMessage: '',
  //     responseVersion: '',
  //     requestSourceService: '',
  //     traceId: uuid.v4()
  //   };
  // }
}
