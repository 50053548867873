
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DealerService } from '../../../dealer-management/service/dealer.service';
import { PartnerService } from '../../../partner-management/service/partner.service';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-progress-bar-dialog',
  templateUrl: './progress-bar-dialog.component.html',
  styleUrls: ['./progress-bar-dialog.component.css']
})
export class ProgressBarDialogComponent implements OnInit {

  progress:any;

  constructor(public dialogRef: MatDialogRef<ProgressBarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.progress =  this.data.obj;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
