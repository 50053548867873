import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../pages/auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    public router: Router,
    private authService: AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url: string = state.url;
      return this.checkLogin(url);
  }

  /**
   * Checks if a user is logged in before activating the secured page.
   */
   checkLogin(url: string): boolean {
    // let roleName = 'teller';
    if (
       this.authService.isLoggedIn()
      // this.keycloakService.isLogged() &&
      // this.keycloakService.hasRole(roleName)
    ) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
