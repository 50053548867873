import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateLocaleFilter} from './date-locale-filter';
import {LocalNumberPipe} from './locale-number.pipe';
import {TranslatorPipe} from './translator-pipe';
import {BdtCommaPipe} from './bdt-comma.pipe';
import { TextLimitPipe } from './text-limit.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [BdtCommaPipe, DateLocaleFilter, LocalNumberPipe, TranslatorPipe, TextLimitPipe],
  declarations: [BdtCommaPipe, DateLocaleFilter, LocalNumberPipe, TranslatorPipe, TextLimitPipe],
  exports: [BdtCommaPipe, DateLocaleFilter, LocalNumberPipe, TranslatorPipe, TextLimitPipe]
})
export class SharedPipesModule {
}
