import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-image-viewer',
  templateUrl: './dialog-image-viewer.component.html',
  styleUrls: ['./dialog-image-viewer.component.scss']
})
export class DialogImageViewerComponent implements OnInit {

  type:any;
  height = "100%";
  constructor(public dialogRef: MatDialogRef<DialogImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log('===================last',this.data.obj);
    if(this.data.obj.endsWith("pdf")){
      this.type = 'pdf';
    }else if(this.data.obj.endsWith("docx")){
      this.type = 'docx';
    }else{
      this.type = 'image';
    }
  }

  contentLoaded(){
    console.log("content load");
  }

}
