import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'localNumber',
})
export class LocalNumberPipe implements PipeTransform {

  public finalEnlishToBanglaNumber = {
    '0': '০',
    '1': '১',
    '2': '২',
    '3': '৩',
    '4': '৪',
    '5': '৫',
    '6': '৬',
    '7': '৭',
    '8': '৮',
    '9': '৯',
    'a few seconds ago': 'কিছুক্ষণ আগে',
    'a minute ago': 'এক মিনিট আগে',
    'minutes ago': 'মিনিট আগে',
    'an hour ago': 'এক ঘণ্টা আগে',
    'hours ago': 'ঘণ্টা আগে',
    'a day ago': 'এক দিন​ আগে',
    'days ago': 'দিন​ আগে',
    'a month ago': 'এক মাস​ আগে',
    'months ago': 'মাস​ আগে',
    'a year ago': 'এক বছর​ আগে',
    'years ago': 'বছর​ আগে'
  };
  public finalBanglaToEnlishNumber = {
    '০': '0',
    '১': '1',
    '২': '2',
    '৩': '3',
    '৪': '4',
    '৫': '5',
    '৬': '6',
    '৭': '7',
    '৮': '8',
    '৯': '9'
  };

  constructor(public translate: TranslateService) {
  }

  transform(number: any, language?: string): string {
    let lang: string;
    if (number === '') {
      return '';
    }
    if (!this.translate.currentLang) {
      lang = this.translate.defaultLang;
    } else {
      lang = this.translate.currentLang;
    }
    if (language) {
      lang = language;
    }
    if (!number && number !== 0 && number !== '0') {
      return;
    }

    if (lang === 'bn') {
      // tslint:disable-next-line: forin
      for (const x in this.finalEnlishToBanglaNumber) {
        number = number.toString().replace(new RegExp(x, 'g'), this.finalEnlishToBanglaNumber[x]);
      }
      return number;
    } else {
      // tslint:disable-next-line: forin
      for (const x in this.finalBanglaToEnlishNumber) {
        number = number.toString().replace(new RegExp(x, 'g'), this.finalBanglaToEnlishNumber[x]);
      }
      return number;
    }
  }
}
