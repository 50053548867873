import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { ApiEndpoints } from "../../../api-endpoints";
import { HttpClient } from "@angular/common/http";
import { Login } from "../../../containers/core/model/login";
import { map } from "rxjs/operators";
import { LocalStorageService } from "../../shared/service/local-storage-service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  private _currentuser: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly currentuser = this._currentuser.asObservable();
  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  setCurrentUserData(data: any) {
    this._currentuser.next(data);
  }

  isLoggedIn(): boolean {
    if (this.localStorageService.getIsAuthenticated()) {
      return true;
    } else {
      return false;
    }
  }

  hasRole(roleName: string): boolean {
    let flag = 0;
    if (flag === 1) {
      return true;
    } else {
      return false;
    }
  }

  login(loginObj: Login): Observable<any> {
    let url = this.apiEndpoints.LOGIN_URL;

    return this.http.post<any>(url, loginObj).pipe(map((value) => value));
  }

 refreshJWTToken(refreshToken:any): Observable<any> {
   let url = this.apiEndpoints.REFRESH_TOKEN;
    return  this.http.post<any>(url, refreshToken).pipe(map((value) => value));
  }

  logout() {

    let userId = this.localStorageService.getUserId();
    let obj = {
      "userId": userId
    }
    let url = this.apiEndpoints.LOGOUT_URL;
    this.http.post<any>(url, obj).pipe(map((value) => value)).subscribe(res=>{
      localStorage.clear();
      this._currentuser.next(null);
      // this.router.navigate(["/"]);
      this.router.navigate(['/admin/login']);
    })
  }

  forgetPassword(obj:any) {
    let url = this.apiEndpoints.FORGET_PASSWORD_URL;
    return this.http.post<any>(url, obj).pipe(map((value) => value));
  }
}
