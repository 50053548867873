export const roles = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER_ROLE_MANAGEMENT: 'USER_ROLE_MANAGEMENT',
  PRODUCT_MANAGEMENT: 'PRODUCT_MANAGEMENT',
  CUSTOMER_MANAGEMENT: 'CUSTOMER_MANAGEMENT',
  ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
  PARTNER_MANAGEMENT: 'PARTNER_MANAGEMENT',
  DEALER_MANAGEMENT: 'DEALER_MANAGEMENT',
  SUPPORT_MANAGEMENT: 'SUPPORT_MANAGEMENT',
  CONFIG_MANAGEMENT: 'CONFIG_MANAGEMENT',


}
