
import { INavData } from '@coreui/angular';
import { roles } from './constants/roles.constant';

interface NavAttributes {
  [propName: string]: any;
}

interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}

interface NavBadge {
  text: string;
  variant: string;
}

interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: any;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/pages/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Role Management',
    url: '/pages/role-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.USER_ROLE_MANAGEMENT]
    },
    children: [
      {
        name: 'Users',
        url: '/pages/role-management/users'
      },
      {
        name: 'Department',
        url: '/pages/role-management/department'
      },
      {
        name: 'Designation',
        url: '/pages/role-management/designation'
      },
        {
        name: 'User Reset Password',
        url: '/pages/role-management/user-reset-password-list'
      }
    ]
  },
  {
    name: 'Product Management',
    url: '/pages/product-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.PRODUCT_MANAGEMENT]
    },
    children:[
      {
        name: 'Brand',
        url: '/pages/product-management/brand'
      },
      {
        name:'Product ',
        url: '/pages/product-management/product'
      }
    ]
  },
  {
    name: 'Customer Management',
    url: '/pages/customer-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.CUSTOMER_MANAGEMENT]
    },
    children: [
      {
        name: 'Customers',
        url: '/pages/customer-management/customers'
      },
      {
        name: 'Corporate Customers',
        url: '/pages/customer-management/corporate-customers'
      },
    ]
  },
  {
    name: 'Order Management',
    url: '/pages/order-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.ORDER_MANAGEMENT]
    },
    children: [
      {
        name: 'All Orders',
        url: '/pages/order-management/order-list/orders'
      },
      {
        name: 'Pending Orders',
        url: '/pages/order-management/order-list/pending'
      },
      {
        name: 'Canceled Orders',
        url: '/pages/order-management/order-list/canceled'
      },
      {
        name: 'Completed Orders',
        url: '/pages/order-management/order-list/completed'
      },

    ]
  },
  {
    name: 'Partner Management',
    url: '/pages/partner-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.PARTNER_MANAGEMENT]
    },
    children: [
      {
        name: 'Partners',
        url: '/pages/partner-management/partners'
      },
      // {
      //   name: 'Transection History',
      //   url: '/pages/partner-management/transection-history-list'
      // },

    ]
  },
  {
    name: 'Dealer Management',
    url: '/pages/dealer-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.DEALER_MANAGEMENT]
    },
    children: [
      {
        name: 'Dealers',
        url: '/pages/dealer-management/dealers'
      },
    ]
  },
  // {
  //   name: 'Report',
  //   url: '/pages/report',
  //   icon: 'icon-user',
  //   children: [

  //   ]
  // },
  {
    name: 'Customer Support',
    url: '/pages/customer-support',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.SUPPORT_MANAGEMENT]
    },
    children: [
      {
        name: 'support-ticket-list',
        url: '/pages/customer-support/support-ticket-list'
      },
    ]
  },
  {
    name: 'Config Management',
    url: '/pages/configuration-management',
    icon: 'icon-user',
    attributes: {
      authorities: [roles.SUPER_ADMIN,roles.CONFIG_MANAGEMENT]
    },
    children: [
      {
        name: 'Basic-setup',
        url: '/pages/configuration-management/basic-setup'
      },
      // {
      //   name: 'Permission',
      //   url: '/pages/role-management/permission'
      // },
      {
        name: 'User Permission',
        url: '/pages/configuration-management/user-group-permission/0'
      },
      {
        name: 'Assign Attachment Type',
        url: '/pages/configuration-management/assign-attachment-type/0'
      },
      {
        name: 'Slider Image',
        url: '/pages/configuration-management/slider-image'
      },
      {
        name: 'Coupon',
        url: '/pages/configuration-management/coupon'
      },
      {
        name: 'Push Notification',
        url: '/pages/configuration-management/push-notification-list'
      },
      {
        name: 'Discount Offer',
        url: '/pages/configuration-management/discount-offer'
      },
      {
        name: 'Document Type List',
        url: '/pages/configuration-management/document'
      },
      {
        name: 'Mobile Wallet',
        url: '/pages/configuration-management/mobile-wallet'
      },
      {
        name: 'Issue Type',
        url: '/pages/configuration-management/issue-type'
      },
      {
        name: 'Cancel Reason',
        url: '/pages/configuration-management/cancel-reason'
      },
      {
        name: 'Template',
        url: '/pages/configuration-management/template'
      },
      {
        name: 'District',
        url: '/pages/configuration-management/district'
      },
      {
        name: 'Thana',
        url: '/pages/configuration-management/thana'
      },
      {
        name: 'Cluster',
        url: '/pages/configuration-management/cluster'
      },
      {
        name: 'Product Size',
        url: '/pages/configuration-management/product-size'
      },
      {
        name: 'Valve Size',
        url: '/pages/configuration-management/valve-size'
      },
    ]
  }
];
