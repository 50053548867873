import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LandingService } from "../pages/shared/service/landing.service";
import { UtilService } from "../pages/shared/service/util.service";

@Component({
  selector: "app-home",
  templateUrl: "home.component.html",
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  productList:any;
  myAQrCodeAppleStore: string = 'https://www.apple.com/app-store/';
  myAQrCodePlayStore: string = 'https://play.google.com/store/';

  constructor(private router: Router,
    private landingService: LandingService,
    private utilService: UtilService

    ) {}

  ngOnInit(): void {
    this.loadList();

  }

  loadList() {
    this.landingService.getLandingProductListTableData().subscribe((res) =>{
     this.productList=res ;
     console.log('===================landing-page-product',this.productList.brandName);

    });
  }

  onClickTermsAndConditions(){
    this.router.navigate(['/admin/terms-and-conditions']);
  }

}
