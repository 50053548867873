import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';
import { LocalStorageService } from '../../shared/service/local-storage-service';
import { UtilService } from '../../shared/service/util.service';
import { MustMatch } from '../../shared/validation/must-match.validator';
import { UserService } from '../service/user.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordPattern = "^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$";
  userForm: FormGroup;
  userId:any;
  merchantStore: any = [
    {value: 'marchant1', viewValue: 'My Profile'},
    // {value: ['/profile'], viewValue: 'My Profile'},


  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,private router: Router,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private utilService: UtilService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.createFormGroup();
    this.userId = this.localStorageService.getUserId();

  }

  createFormGroup(){
    this.userForm = this.fb.group({
      password: ['',[Validators.required,Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['',[Validators.required,Validators.pattern(this.passwordPattern)]],
    },{
      validator: MustMatch('password', 'confirmPassword')
    }
    );
  }

  onNoClick(){
    this.dialogRef.close();
  }

  onOptionsSelected(event:any) {
    console.log(event);
 }

 checkDisable() {
  return !this.userForm.valid;
}


 get password() {
  return this.userForm.get("password");
}

get confirmPassword() {
  return this.userForm.get("confirmPassword");
}

  onChange(event:any){
    console.log(event.value);
    if(event.value==='profile'){
     this.router.navigateByUrl('/pages/user-management/profile');
    }else{
     this.router.navigateByUrl('/pages/user-management/change-password');
    }
    // this.router.navigateByUrl('/pages/user-management/change-password');

 }
 onSubmit(){
  let obj = {
    "userId": this.userId,
    "password": this.userForm.value.password,
    "confirmPassword" : this.userForm.value.confirmPassword
}
console.log(obj);
  this.userService.UserResetPassword(obj).subscribe(
    (res) => {
      this.utilService.showSnackBarMessage(
        "Password Changed Successfully",
        this.utilService.TYPE_MESSAGE.SUCCESS_TYPE
      );
      this.dialogRef.close();
      this.authService.logout();
     // this.router.navigate(["/pages/role-management/user-reset-password-list"]);
    },
    (error) => {
      this.utilService.showSnackBarMessage(
        error.message,
        this.utilService.TYPE_MESSAGE.ERROR_TYPE
      );
    }
  );
 }

 goToList(){
  this.router.navigate(['/pages/role-management/users']);
}


}
