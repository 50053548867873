import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { version } from "../constants/gas-monkey-constants";

@Component({
  selector: "app-landing-page",
  templateUrl: "landing-page.component.html",
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  version = version;
  constructor(private router: Router) {}

  ngOnInit(): void {

  }

  onClickTermsAndConditions(){
    this.router.navigate(['/terms-and-conditions']);
  }

}
