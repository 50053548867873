import { ROOT_URL } from "../environments/environment";


export class ApiEndpoints {
  // ROOT_URL = 'https://testapi-k8s.oss.net.bd/gas-monkey'; //UAT
  // ROOT_URL = 'http://192.168.30.83:8801'; // for DEV SERVER
  //End-Point
  LOGIN_URL = `${ROOT_URL}/api/v1/auth/login`;
  LOGOUT_URL = `${ROOT_URL}/api/v1/auth/logout`;
  FORGET_PASSWORD_URL =`${ROOT_URL}/api/v1/auth/forget-password`;
  REFRESH_TOKEN = `${ROOT_URL}/api/v1/auth/refresh-token`;
  GROUP_DROPDOWN_LIST = `${ROOT_URL}/api/v1/admin/group-info/dropdown-list`;
  LANGUAGE_DROPDOWN_LIST = `${ROOT_URL}/api/v1/admin/language/dropdown-list`;
  USER_REGISTRATION = `${ROOT_URL}/api/v1/admin/user-registration`;
  USERS_REQUEST_API = `${ROOT_URL}/api/v1/admin/users`;
  DESIGNATION_DROPDOWN_LIST = `${ROOT_URL}/api/v1/admin/designation/dropdown-list`;
  DEPARTMENT_DROPDOWN_LIST = `${ROOT_URL}/api/v1/admin/department/dropdown-list`;

  STORE_REQUEST_API =    `${ROOT_URL}/api/v1/admin/stores`;
  BRAND_BASE_URL = `${ROOT_URL}/api/v1/admin/brand`;
  ALL_DROPDOWN_LIST="/all-dropdown-list";
  PRODUCT_BASE_URL = `${ROOT_URL}/api/v1/admin/product`;
  PRODUCT_SIZE_BASE_URL = `${ROOT_URL}/api/v1/admin/product-size`;
  PRODUCT_VALVE_SIZE_BASE_URL = `${ROOT_URL}/api/v1/admin/product-valve-size`;
  UPLOAD_PRODUCT_IMAGE_URL = `${ROOT_URL}/api/v1/admin/upload/product-image`;
  CREATE_PRODUCT_IMAGE_URL = `${ROOT_URL}/api/v1/admin/product-image`;
  FIND_PRODUCT_IMAGE_URL = `${ROOT_URL}/apapi/v1/admin/product`;
  CUSTOMER_LIST_URL = `${ROOT_URL}/api/v1/admin/customer`;
  CORPORATE_CUSTOMER_LIST_URL = `${ROOT_URL}/api/v1/admin/corporate-customer`;
  CUSTOMER_TYPE_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/customer-type/dropdown-list`;
  CUSTOMER_TYPE_DROPDOWN_ALL_LIST =`${ROOT_URL}/api/v1/admin/customer-type/dropdown-list-all`;
  CUSTOMER_DISTRICT_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/district`;
  CUSTOMER_THANA_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/thana`;
  CUSTOMER_CLUSTER_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/cluster`;
  CUSTOMER_AREA_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/cluster/dropdown-list`;
  CUSTOMER_DD_LIST =`${ROOT_URL}/api/v1/admin/customer/dd-list`;
  CUSTOMER_BOUNDARY_URL = `${ROOT_URL}/api/v1/customer/thana/boundaries`;
  CREATE_BRAND_IMAGE_URL = `${ROOT_URL}/api/v1/admin/brand-image`;
  CONFIG_DATA_URL = `${ROOT_URL}/api/v1/admin/config-data`;
  ORDER_LIST_URL = `${ROOT_URL}/api/v1/admin/order-list`;
  PENDING_ORDER_LIST = `${ROOT_URL}/api/v1/admin/pending-order-list`;
  ORDER_GET_BY_ID_URL = `${ROOT_URL}/api/v1/admin/get-order-by-id`;
  ORDER_LIST_BY_STATUS_URL = `${ROOT_URL}/api/v1/admin/order-list-by-status`;
  ORDER_STATUS_DROPDOWN_LIST = `${ROOT_URL}/api/v1/admin/order-status/dropdown-list`;

  RESET_PASSWORD_URL =  `${ROOT_URL}/api/v1/admin/reset-password`;
  SLIDER_IMAGE_LIST_URL = `${ROOT_URL}/api/v1/admin/slider-image`;
  COUPON_LIST_URL = `${ROOT_URL}/api/v1/admin/coupon`;
  DISCOUNT_DATA_URL = `${ROOT_URL}/api/v1/admin/service-discount`;
  SEND_NOTIFICATION_URL = `${ROOT_URL}/api/v1/admin/send-notification`;
  ATTACHMENT_URL = `${ROOT_URL}/api/v1/admin/attachment-type`;
  ATTACHMENT_TYPE_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/attachment-type/dropdown-list`

  PARTNER_URL = `${ROOT_URL}/api/v1/admin/partner`;
  PARTNER_RATING_URL = `${ROOT_URL}/api/v1/admin/rating-details`;
  DEALER_URL = `${ROOT_URL}/api/v1/admin/dealer`;
  PARTNER_UTILITY_URL = `${ROOT_URL}/api/v1/partner`;
  DEALER_UTILITY_URL = `${ROOT_URL}/api/v1/dealer`;
  DEALER_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/dealer/dropdown-list`;
  PARTNER_DROPDOWN_LIST =`${ROOT_URL}/api/v1/admin/partner/dropdown-list`;

  PARTNER_DROPDOWN_LIST_BY_AREA_ID =`${ROOT_URL}/api/v1/admin/partner/dropdown-list`;
  ORDER_FORWARD_BASE_URL = `${ROOT_URL}/api/v1/admin/forward-order`;
  MOBILE_BANKING_URL = `${ROOT_URL}/api/v1/admin/mobile-banking-info`;
  MOBILE_BANKING_DROPDOWN_URL = `${ROOT_URL}/api/v1/admin/mobile-banking-info/dropdown-list`;
  ISSUE_TYPE_URL = `${ROOT_URL}/api/v1/admin/issue-type`;
  CANCEL_REASON_URL = `${ROOT_URL}/api/v1/admin/cancel-reason`;
  TEMPLATE_URL = `${ROOT_URL}/api/v1/admin/template`;

  PARTNER_APPROVED_BASE_URL = `${ROOT_URL}/api/v1/admin/partner/approved`;
  Dealer_APPROVED_BASE_URL = `${ROOT_URL}/api/v1/admin/dealer/approved`;
  FILE_UPLOAD_URL = `${ROOT_URL}/api/v1/file/upload`;

 //
 DROPDOWN_LIST_API_URL="/dropdown-list";
 ACTIVE_DROPDOWN_LIST_API_URL="/active-dropdown-list";
 NOTIFICATION_LIST_URL = `${ROOT_URL}/api/v1/admin/notification-list`;
 SUPPORT_TICKET_LIST_URL = `${ROOT_URL}/api/v1/admin/support-ticket`;
 PUSH_NOTIFICATION_URL = `${ROOT_URL}/api/v1/admin/push-notification`;


 API_ROUTE_DIRECTION_URL = "https://api.openrouteservice.org/v2/directions/driving-car";
 ORDER_TRACE_URL = `${ROOT_URL}/api/v1/admin/order-trace`;

 DEPARTMENT_URL = `${ROOT_URL}/api/v1/admin/department`;
 DESIGNATION_URL = `${ROOT_URL}/api/v1/admin/designation`;
 PARTNER_PAYMENT_URL = `${ROOT_URL}/api/v1/admin/partner/payment`;
 TRANSECTION_PAYMENT_URL = `${ROOT_URL}/api/v1/admin/transaction/payment`;
 APP_MENU_URL = `${ROOT_URL}/api/v1/admin/app-menu`;
 ASSIGN_ROLE_MENU_URL = `${ROOT_URL}/api/v1/admin/assign-role-menu`;
 PRODUCT_STATUS_UPDATE_URL = `${ROOT_URL}/api/v1/admin/product/update-status`;
 CUSTOMER_ATTACHMENT_URL = `${ROOT_URL}/api/v1/admin/customer-attachment`;
 DEALER_STATUS_UPDATE_URL = `${ROOT_URL}/api/v1/admin/dealer/update-status`;
 PARTNER_STATUS_UPDATE_URL = `${ROOT_URL}/api/v1/admin/partner/update-status`;

CHECK_AVAILABLE_EMAIL_URL = `${ROOT_URL}/api/v1/utility/admin/check-email`;
CHECK_AVAILABLE_MOBILE_URL = `${ROOT_URL}/api/v1/utility/admin/check-phone`;
CHECK_AVAILABILITY_MOBILE_URL = `${ROOT_URL}/api/v1/utility/check-availability`;

LANDING_PAGE_PRODUCT_URL = `${ROOT_URL}/api/v1/utility/product-list-for-web`;


DISTRICT_LIST_URL = `${ROOT_URL}/api/v1/admin/district-list`;
DISTRICT_URL = `${ROOT_URL}/api/v1/admin/district`;
THANA_LIST_URL = `${ROOT_URL}/api/v1/admin/thana-list`;
THANA_BY_ID_URL = `${ROOT_URL}/api/v1/admin/thana-by-id`;
THANA_URL = `${ROOT_URL}/api/v1/admin/thana`;
CLUSTER_LIST_URL = `${ROOT_URL}/api/v1/admin/cluster-list`;
CLUSTER_URL = `${ROOT_URL}/api/v1/admin/cluster`;
CLUSTER_BY_ID_URL = `${ROOT_URL}/api/v1/admin/cluster-by-id`;
UPDATE_ORDER_STATUS_URL = `${ROOT_URL}/api/v1/admin/order/update-order-status`;

AVAILABLE_PARTNER_LIST =`${ROOT_URL}/api/v1/admin/partner/available-partner-list`;

AVAILABLE_PARTNER_DELIVARY_LAT_LOBG = `${ROOT_URL}/api/v1/admin/partner/partner-and-delivery-lat-lng`;

CUSTOMER_ATTACHEMNT_CREATE = `${ROOT_URL}/api/v1/admin/attachment`;
CUSTOMER_ATTACHEMNT_GET_LIST = `${ROOT_URL}/api/v1/admin/customer-attachment`;

ASSIGN_ATTACHMENT_TYPE = `${ROOT_URL}/api/v1/admin/assign-attachment-type`;

TIME_SLOT_LIST = `${ROOT_URL}/api/v1/customer/time-slot`;

PRODUCT_DETAILS_FILTER = `${ROOT_URL}/api/v1/customer/product-detail-filter`;
CUSTOMER_GET_CART_BY_CUSTOMER_Id = `${ROOT_URL}/api/v1/admin/get-cart`;
CUSTOMER_Add_TO_CART = `${ROOT_URL}/api/v1/admin/add-to-cart`;
CUSTOMER_DELETE_FROM_CART = `${ROOT_URL}/api/v1/admin/delete-from-cart`;
CUSTOMER_ADD_REMOVE_CART = `${ROOT_URL}/api/v1/admin/coupon-add-remove-request`;
CUSTOMER_CHANGE_CART_REQUEST = `${ROOT_URL}/api/v1/admin/change-cart-request`;
CUSTOMER_ORDER_PLACE = `${ROOT_URL}/api/v1/admin/order`;
CUSTOMER_TIME_SLOT_BY_DATE = `${ROOT_URL}/api/v1/admin/time-slot`;

MODIFY_ORDER_BY_ID = `${ROOT_URL}/api/v1/admin/modify-order-by-id`;

COUPON_VALIDATE_BY_CODE = `${ROOT_URL}/api/v1/admin/coupon/validate`;

ASSIGN_ATTACHMENT_TYPE_DROPDOWNLIST = `${ROOT_URL}/api/v1/admin/attachment-type/dropdown-list`;

ORDER_PRODUCT_BY_ORDER_ID = `${ROOT_URL}/api/v1/admin/get-order-product-by-id`;
ORDER_MODIFY_CALCULATION_URL = `${ROOT_URL}/api/v1/admin/order-modify`;
CHANGE_ORDER_REQUEST = `${ROOT_URL}/api/v1/admin/change-order-request`;
ORDER_MODIFICATION_COUPON_ADD_REMOVE = `${ROOT_URL}/api/v1/admin/coupon-add-remove`;
ORDER_PRODUCT_UPDATE = `${ROOT_URL}/api/v1/admin/order-product-update`;

OPEN_STREAT_MAP_GET_ADDRESS_URL =  `${ROOT_URL}/api/v1/admin/get-address`;

}
