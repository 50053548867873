import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "terms-and-conditions.component.html",
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {

  }
}
