import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpoints } from '../../../api-endpoints';
import { ChangeOrderModifyBean } from '../model/change-order-modify-bean';
import { OrderModifyBean } from '../model/order-modify-bean';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  HTTPOptions:Object = {
    observe: 'response',
    responseType: 'text'
 }

 private apiEndpoints: ApiEndpoints = new ApiEndpoints();

  constructor(private http: HttpClient) { }

  getOrderPageableTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.ORDER_LIST_URL;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }


getPendingOrderListData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.PENDING_ORDER_LIST;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getOrderListDataByStatusValue(page: number, size: number , value:any): Observable<any> {
    let url = this.apiEndpoints.ORDER_LIST_BY_STATUS_URL+ '/' + value;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getOrderViewById(id:any): Observable<any>{
    let url = this.apiEndpoints.ORDER_GET_BY_ID_URL + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  momdifyOrderViewById(id:any): Observable<any>{
    let url = this.apiEndpoints.MODIFY_ORDER_BY_ID + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }


  // dorpdown list

  getOrderStatusDropList(): Observable<any> {
    let url = this.apiEndpoints.ORDER_STATUS_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getCustomerDistrictDropList(): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_DISTRICT_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getAvailablePartnerList(): Observable<any> {
    let url = this.apiEndpoints.AVAILABLE_PARTNER_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getAvailablePartnerListByOrderId(orderId : any): Observable<any> {
    let url = this.apiEndpoints.AVAILABLE_PARTNER_DELIVARY_LAT_LOBG + '/' + orderId;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }



  getCustomerThanaDropList(districtId:any): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_THANA_DROPDOWN_LIST + '/' + districtId;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getCustomerClusterDropList(thanaId:any): Observable<any> {
    let url = this.apiEndpoints.CUSTOMER_CLUSTER_DROPDOWN_LIST + '/' + thanaId;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }


  getPartnerDropListByAreaId(areaId:any): Observable<any> {
    let url = this.apiEndpoints.PARTNER_DROPDOWN_LIST_BY_AREA_ID + '/' + areaId;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }


  orderForward(Obj: any): Observable<any> {
    let url = this.apiEndpoints.ORDER_FORWARD_BASE_URL;
    return this.http.post<any>(url, Obj).pipe(map(value => value))
  }


  getAPIRouteDirection(apiKey:any,startLatLong:any, endLatLong:any){
    let url = this.apiEndpoints.API_ROUTE_DIRECTION_URL+'?api_key='+apiKey+'&start='+startLatLong+'&end='+endLatLong;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getOrderTraceByOrderId(id:any){
    let url = this.apiEndpoints.ORDER_TRACE_URL + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  // UPDATE_ORDER_STATUS_URL
  updateOrderStatusById(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.UPDATE_ORDER_STATUS_URL + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }


  //cancel-reason dropdown
  getCancelReasonDropDownList(){
    let url = this.apiEndpoints.CANCEL_REASON_URL + this.apiEndpoints.DROPDOWN_LIST_API_URL;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getOrderProductByOrderId(orderId: string){
    // 
    let url = this.apiEndpoints.ORDER_PRODUCT_BY_ORDER_ID + '/' + orderId;
    return this.http.get<any>(url).pipe(map(value => value)
    );
  }
  // search

  orderSearch(page: number, size: number, mapData: Map<string, any>): Observable<any> {

    mapData.set("page", page)
    mapData.set("size", size)

    const params = this.getParams(mapData)

    let url = this.apiEndpoints.ORDER_LIST_URL;
    const fullUrl = `${url}/search`;
    return this.http.get<any>(fullUrl, { params }).pipe(
      map(value => value)
    );
  }

  ordermodifyCalculation( beans : OrderModifyBean): Observable<any> {
    let url = this.apiEndpoints.ORDER_MODIFY_CALCULATION_URL;
    return this.http.post<any>(url, beans).pipe(map(value => value))
  }

  
  changeOrdermodifyRequest( beans : ChangeOrderModifyBean): Observable<any> {
    let url = this.apiEndpoints.CHANGE_ORDER_REQUEST;
    return this.http.post<any>(url, beans).pipe(map(value => value))
  }

  
  ordermodifyAddRemoveCouponRequest( beans : ChangeOrderModifyBean): Observable<any> {
    let url = this.apiEndpoints.ORDER_MODIFICATION_COUPON_ADD_REMOVE;
    return this.http.post<any>(url, beans).pipe(map(value => value))
  }

  orderProductUpdateRequest( beans : any): Observable<any> {
    let url = this.apiEndpoints.ORDER_PRODUCT_UPDATE;
    return this.http.post<any>(url, beans).pipe(map(value => value))
  }

  getParams(map: Map<string, any>): HttpParams {

    let params = new HttpParams()
    map.forEach((value: string, key: any) => {
      params = params.append(key, value.toString());
      console.log(key, value);
    });

    return params;
  }

}
