import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiEndpoints } from "../../../api-endpoints";
import { MasterService } from "../../../containers/core/master-component/master-service/master-service";
import { RestClient } from "../../shared/service/rest-client";
import {} from "../../../api-endpoints";

@Injectable({
  providedIn: 'root'
})
export class UserService extends MasterService<any> {

  HTTPOptions:Object = {
    observe: 'response',
    responseType: 'text'
 }
 
 httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
}

  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  constructor(private http: HttpClient,
    _restClient: RestClient) {
    super(_restClient, 'https://testapi-k8s.oss.net.bd/gas-monkey','/api/v1/admin/users', '1.0');
   }

  getGroupDropdownList(): Observable<any>{
    let url = this.apiEndpoints.GROUP_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getDesignationDropdownList(): Observable<any>{
    let url = this.apiEndpoints.DESIGNATION_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getDepartmentDropdownList(): Observable<any>{
    let url = this.apiEndpoints.DEPARTMENT_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  getLanguageDropdownList(): Observable<any>{
    let url = this.apiEndpoints.LANGUAGE_DROPDOWN_LIST;
    return this.http.get<any>(url).pipe(
      map(value => value)
    );
  }

  UserRegistration(Obj: any): Observable<any>{
    let url = this.apiEndpoints.USER_REGISTRATION;
    return this.http.post<any>(url, Obj,this.HTTPOptions).pipe(map(value => value))
  }

  getUsersTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.USERS_REQUEST_API;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getUserById(id:any): Observable<any>{
    let url = this.apiEndpoints.USERS_REQUEST_API + '/' + id;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  userUpdate(id:any, obj:any): Observable<any>{
    let url = this.apiEndpoints.USERS_REQUEST_API + '/' + id;
    return this.http.put<any>(url,obj).pipe(
      map(value => value)
    );
  }


  UserResetPassword(Obj: any): Observable<any>{
    let url = this.apiEndpoints.RESET_PASSWORD_URL;
    return this.http.post<any>(url, Obj,this.HTTPOptions).pipe(map(value => value))
  }


    //department
    getDepartmentListTableData(page: number, size: number): Observable<any> {
      let url = this.apiEndpoints.DEPARTMENT_URL;
      const fullUrl = `${url}?page=${page}&size=${size}`;
      return this.http.get<any>(fullUrl).pipe(
        map(value => value)
        );
    }

    createDepartment(obj:any): Observable<any> {
      let url = this.apiEndpoints.DEPARTMENT_URL;
      return this.http.post<any>(url,obj).pipe(
        map(value => value)
        );
    }

    getDepartmentById(id:any): Observable<any>{
      let url = this.apiEndpoints.DEPARTMENT_URL + '/' + id;
      return this.http.get<any>(url).pipe(
        map(value => value)
        );
    }

    updateDepartment(id:any, obj:any): Observable<any>{
      let url = this.apiEndpoints.DEPARTMENT_URL + '/' + id;
      return this.http.put<any>(url,obj).pipe(
        map(value => value)
      );
    }

    //designation
    getDesignationListTableData(page: number, size: number): Observable<any> {
      let url = this.apiEndpoints.DESIGNATION_URL;
      const fullUrl = `${url}?page=${page}&size=${size}`;
      return this.http.get<any>(fullUrl).pipe(
        map(value => value)
        );
    }

    createDesignation(obj:any): Observable<any> {
      let url = this.apiEndpoints.DESIGNATION_URL;
      return this.http.post<any>(url,obj).pipe(
        map(value => value)
        );
    }

    getDesignationById(id:any): Observable<any>{
      let url = this.apiEndpoints.DESIGNATION_URL + '/' + id;
      return this.http.get<any>(url).pipe(
        map(value => value)
        );
    }

    updateDesignation(id:any, obj:any): Observable<any>{
      let url = this.apiEndpoints.DESIGNATION_URL + '/' + id;
      return this.http.put<any>(url,obj).pipe(
        map(value => value)
      );
    }


    ////menu
    getAppMenuList(): Observable<any>{
      let url = this.apiEndpoints.APP_MENU_URL;
      return this.http.get<any>(url).pipe(
        map(value => value)
      );
    }

    getAssignRoleMenuByUserGroupId(id:any): Observable<any>{
      let url = this.apiEndpoints.ASSIGN_ROLE_MENU_URL + '/' + id;
      return this.http.get<any>(url).pipe(
        map(value => value)
        );
    }

    AssignRoleMenuPatchRequest(id:any, obj:any): Observable<any>{
      console.log('patch-request---',obj);
      let url = this.apiEndpoints.ASSIGN_ROLE_MENU_URL + '/' + id;
      return this.http.patch<any>(url,obj,this.httpOptions).pipe(
        map(value => value)
      );
    }

    ////menu
    getEmailCheckAvailable(email:any): Observable<any>{
      let url = this.apiEndpoints.CHECK_AVAILABLE_EMAIL_URL + '/' + email;
      return this.http.get<any>(url).pipe(
        map(value => value)
      );
    }

    getMobileCheckAvailable(mobile:any): Observable<any>{
      let url = this.apiEndpoints.CHECK_AVAILABLE_MOBILE_URL + '/' + mobile;
      return this.http.get<any>(url).pipe(
        map(value => value)
      );
    }

    getMobileCheckAvailability(mobile:any): Observable<any>{
      let url = this.apiEndpoints.CHECK_AVAILABILITY_MOBILE_URL + '/' + mobile;
      return this.http.get<any>(url).pipe(
        map(value => value)
      );
    }

    //search

    userSearch(page: number, size: number, mapData: Map<string, any>): Observable<any> {

      mapData.set("page", page)
      mapData.set("size", size)

      const params = this.getParams(mapData)

      let url = this.apiEndpoints.USERS_REQUEST_API;
      const fullUrl = `${url}/search`;
      return this.http.get<any>(fullUrl, { params }).pipe(
        map(value => value)
      );
    }


    getParams(map: Map<string, any>): HttpParams {

      let params = new HttpParams()
      map.forEach((value: string, key: any) => {
        params = params.append(key, value.toString());
        console.log(key, value);
      });

      return params;
    }
}

