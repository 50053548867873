export const OBJ_LIST = 'list';
export const OBJ_NEW = 'new';
export const OBJ_EDIT = 'edit';
export const TITLE_SUCCESS = 'Success!!';
export const TITLE_ERROR = 'Error!!';
export const PROFILE_IMAGE_TAG = 'PROFILE_IMAGE';
export const SIGNATURE_TAG = 'SIGNATURE';
export let version = "1.2.3";


///TODO
//1.always version update UAT and GAS-MONKEY-UAT
//2.environment prod URL change UAT and GAS-MONKEY-UAT
//
