import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private storageService: StorageService) {
  }


  getJWTToken(): string{
    return this.storageService.read('jwt_token');
  }

  getJWTRefreshToken(): string{
    return this.storageService.read('jwt_refresh_token');
  }

  setJWTToken(jwttoken: string):void{
    this.storageService.save('jwt_token', jwttoken);
  }

  setJWTRefreshToken(refreshtoken: string): void{
    this.storageService.save('jwt_refresh_token', refreshtoken);
  }

  setIsAuthenticated(authenticated:any){
    this.storageService.save('isAuthenticated', authenticated);
  }

  getIsAuthenticated(): string{
    return this.storageService.read('isAuthenticated');
  }

  setUserName(username: any): void{
    this.storageService.save('user_name', username);
  }

  getUserName(): string{
    return this.storageService.read('user_name');
  }

  setUserId(userId: any): void{
    this.storageService.save('userId', userId);
  }

  getUserId(): string{
    return this.storageService.read('userId');
  }


  setRoleName(rolename: any): void{
    this.storageService.save('role_name', rolename);
  }

  getRoleName(): string{
    return this.storageService.read('role_name');
  }

  setAuthorityName(authorityname: any): void{
    this.storageService.save('authority_name', authorityname);
  }

  getAuthorityName(): string{
    return this.storageService.read('authority_name');
  }



  setFirebaseToken(firebaseToken: string):void{
    localStorage.setItem('firebase_token', firebaseToken);
  }

  getFirebaseToken(): string{
    return localStorage.getItem('firebase_token');
  }

}
