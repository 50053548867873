import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from '../service/order.service';
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  orderViewData:any;
  orderHistoryList:any;
  constructor(public dialogRef: MatDialogRef<OrderViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService : OrderService){}
  ngOnInit() {

    console.log(this.data.obj);
    this.oderViewData(this.data.obj.id);
  }

  oderViewData(id:any){
     this.orderService.getOrderViewById(id).subscribe(res=>{
         console.log("order-view:",res);
         this.orderViewData = res;
         console.log("=======order history bean=====:",res.orderHistoryBeans);
         this.orderHistoryList = res.orderHistoryBeans;
     });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  convertToDateWithYear(data: any) {
    // console.log('****>>>',data);
    if (data) {
      let dateStr = Date.parse(data);
      // console.log(data,"=======>>>>>>>",dateStr)
      let date = new Date(dateStr);
      let str =date.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', year: 'numeric', hour12: true, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
      // console.log("=======>>>>>>>",str)
      return str;
    } else {
      return data;
    }
  }

  delivaryCharge(response : any){
    return response?.serviceCharge ? response?.serviceCharge: 0 + response?.deliveryChargeDiscount ? response?.deliveryChargeDiscount : 0;
}

}
