
export class TextData  {
  controlType = 'text';
  key: string;
  fieldWidth: number;
  name: string;
  id: string;
  label: string;
  type: string;
  canShow: boolean;
  canSort: boolean;
  order: number;
  textAlign?:string;
  listName?: string;
  width?:string;
  showToggle?: boolean;
  showToolTip?: boolean;
  clickable?: boolean;
  canColor?: boolean;
  canTranslate?: boolean;

  constructor(options: Partial<TextData> = {}) {
    this.key = options.key;
    this.fieldWidth = options.fieldWidth;
    this.name = options.name;
    this.id = options.id;
    this.label = options.label;
    this.type = options.type;
    this.canShow = options.canShow;
    this.canSort = options.canSort;
    this.order = options.order;
    this.listName = options.listName || '';
    this.textAlign = options.textAlign || undefined;
    this.width = options.width || undefined;
    this.showToggle = options.showToggle || false;
    this.showToolTip = options.showToolTip || false;
    this.clickable = options.clickable || false;
    this.canColor = options.canColor || false;
    this.canTranslate = options.canTranslate || false;
  }
}
