import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  imageUrl = '../../../../assets/img/caurosal/slider.png';
  event_list = [

    {
      event:' Event 1',
      eventLocation:'Dhaka',
      eventDescription:'Gas Monkey iconic Banner',
      img: this.imageUrl,
      eventStartDate: new Date(),
      eventEndingDate: new Date()
    },
    // {
    //   event:' Event 2',
    //   eventLocation:'Barcelona',
    //   eventDescription:'Barcelona is another good city',
    //   img: this.imageUrl,
    //   eventStartDate: new Date(),
    //   eventEndingDate: new Date()
    // },
  ]

  upcoming_events =  this.event_list.filter( event => event.eventStartDate > new Date());
  past_events = this.event_list.filter(event => event.eventEndingDate < new Date());
  current_events =  this.event_list.filter( event => (event.eventStartDate >= new Date() && (event.eventEndingDate <= new Date())))
  constructor() { }

  ngOnInit(): void {
  }

}
