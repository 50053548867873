import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, delay, tap, takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  paramsName: any;

  private _unsubscriber$: Subject<any> = new Subject();
  private searchTextSubject = new Subject<string>();
  searchText$!: Observable<string>;

  @Input() isShowAdvanceSearchIcon
  @Output() searchText = new EventEmitter<string>();
  @Output() openAdvanceSearchDialog = new EventEmitter<string>();




  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,) {
      this.activatedRoute.params.subscribe((params) => {
        this.paramsName = params.name;
        if (this.paramsName == "orders") {
          this.isShowAdvanceSearchIcon=true;
        }else{
          this.isShowAdvanceSearchIcon=false;
        }

      });

     }

  ngOnInit(): void {
    console.log('isShowAdvanceSearchIcon',this.isShowAdvanceSearchIcon)
    this.searchText$ = this.searchTextSubject.asObservable()
    .pipe(
      // tap(text => console.log(text)),
      switchMap(text => of(text).pipe(delay(1000))),
    );

  this.searchText$
    .pipe(
      tap(text => console.log(text)),
      tap(text => this.searchText.emit(text)),
      takeUntil(this._unsubscriber$)
    )
    .subscribe();

  }

  search(text: string) {
    this.searchTextSubject.next(text)

  }

  openAdvanceSearch(){
    this.openAdvanceSearchDialog.emit('openDialog');
  }

}
