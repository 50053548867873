import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {StorageService} from './storage.service';
import { AuthService } from '../../auth/service/auth.service';
import { throwError } from 'rxjs';


@Injectable({providedIn: 'root'})
export class RestClient {
  private token = {};

  constructor(private _http: HttpClient, private authService: AuthService, private storageService: StorageService) {
  }

  private get _headers(): { headers: HttpHeaders } {
    this.token = this.storageService.read('jwt_token');
    if (!this.token) {
      this.authService.logout();
    }
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (this.token || '')
      })
    };
  }

  post(url: string, data: any, file: boolean = false): Observable<any> {
    try {
      if (file) {
        return this._http.post<any>(url, data, {
          responseType: 'blob' as 'json',
          headers: this._headers.headers
        });
      }
      return this._http.post<any>(url, data, this._headers);
    } catch (err) {
      return throwError(err.message);
    }
  }
}
