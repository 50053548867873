import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@hayafer/angular-editor';
import { FileUploadModule } from 'ng2-file-upload';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ToastrModule } from 'ngx-toastr';
import { MasterListComponent } from '../../containers/core/master-component/master-list/master-list.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedPipesModule } from './pipe/shared-pipe.module';
import { ChartsModule } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { DialogImageViewerComponent } from './components/dialog-image-viewer/dialog-image-viewer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ProgressBarDialogComponent } from './components/progress-bar/progress-bar-dialog.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {MatStepperModule} from '@angular/material/stepper';
import { CustomerSelectLatLongMapComponent } from '../customer-management/customer-select-lat-long-map/customer-select-lat-long-map.component';
import { SearchDialogComponent } from './components/search-dialog/search-dialog.component';
import { SearchComponent } from './components/search/search.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const lang = "en-US";

@NgModule({
  declarations: [
    NotFoundComponent,
    MasterListComponent,
    DialogImageViewerComponent,
    ConfirmDialogComponent,
    ProgressBarDialogComponent,
    CustomerSelectLatLongMapComponent,
    SearchDialogComponent,
    SearchComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MaterialModule,
    LeafletModule,
    FileUploadModule,
    SharedPipesModule,
    ChartsModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgxDocViewerModule,
    MatStepperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,

    }),
    NgxMatTimepickerModule.setLocale(lang)

  ],exports:[
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    AngularEditorModule,
    FileUploadModule,
    LeafletModule,
    ToastrModule,
    ChartsModule,
    MasterListComponent,
    NgCircleProgressModule,
    NgxMatTimepickerModule,
    DialogImageViewerComponent,
    ConfirmDialogComponent,
    NgxDocViewerModule,
    NgbModule,
    MatStepperModule,
    SearchDialogComponent,
    SearchComponent
  ],providers:[
    DatePipe
  ],
  entryComponents: [ConfirmDialogComponent,ProgressBarDialogComponent,CustomerSelectLatLongMapComponent],
})
export class SharedModule {}
