
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DealerService } from '../../../dealer-management/service/dealer.service';
import { OrderService } from '../../../order-management/service/order.service';
import { PartnerService } from '../../../partner-management/service/partner.service';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  id:any;
  body:any;
  label:any;


  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private partnerService: PartnerService,
    private utilService: UtilService,
    private router: Router,
    private dealerService: DealerService,
    private orderService : OrderService) {
    this.title = data.obj.title;
    this.message = data.obj.message;
    this.id = data.id;
    this.body = data.body;
    this.label = data.label;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true

    if(this.label == 'partner'){
      this.partnerService.partnerStatusUpdate(this.id,this.body).subscribe(res =>{
        console.log("-------------Partner-Status-update-------------",res);
        this.utilService.showSnackBarMessage('Partner Status Updated Successfully',this.utilService.TYPE_MESSAGE.SUCCESS_TYPE);
        this.router.navigate(['/pages/partner-management/partners']);
      },error => {
        this.utilService.showSnackBarMessage(error.message,this.utilService.TYPE_MESSAGE.ERROR_TYPE);
      });
    }
    else if(this.label == 'dealer'){
         this.dealerService.dealerStatusUpdate(this.id,this.body).subscribe(res =>{
            console.log("-------------dealer-Status-update-------------",res);
            this.utilService.showSnackBarMessage('Dealer Status Updated Successfully',this.utilService.TYPE_MESSAGE.SUCCESS_TYPE);
            this.router.navigate(['/pages/dealer-management/dealers']);
          },error => {
            this.utilService.showSnackBarMessage(error.message,this.utilService.TYPE_MESSAGE.ERROR_TYPE);
          });
    }

    else if( this.label == 'order_received'){

      let obj = {
      "cancelReasonId": "",
      "status": "received"
      }
      this.orderService.updateOrderStatusById(this.id,obj).subscribe(res=>{
        console.log("-------------update-order-status-data-------------",res);
        this.utilService.showSnackBarMessage('order-status Received Successfully',this.utilService.TYPE_MESSAGE.SUCCESS_TYPE);
        // this.router.navigate(['/pages/customer-support/support-ticket-list']);
      },error => {
        this.utilService.showSnackBarMessage(error.message,this.utilService.TYPE_MESSAGE.ERROR_TYPE);
      })
    }
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}
