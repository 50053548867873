import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../pages/auth/service/auth.service';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../../pages/shared/service/local-storage-service';

@Injectable()
export class SecuredHttpInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('api/v1/auth') || request.url.includes('/v2/directions' )  || request.url.includes('api/v1/customer/all-product')) {
      return next.handle(request);
    } else {

      console.log('token-expire--', this.tokenExpired(this.localStorageService.getJWTToken()));
      //if expired
      if (this.localStorageService.getJWTToken() && this.tokenExpired(this.localStorageService.getJWTToken())) {
        return this.handleApiRequest(next, request);
      }
      else {
        //if not expired
        let token = this.localStorageService.getJWTToken();
        let authReq: HttpRequest<any> = null;
        authReq = request.clone({ setHeaders: this.getAPIAuthenticationHeaders(token) });
        return next.handle(authReq);
      }
    }
  }


  handleApiRequest(next, request): Observable<HttpEvent<any>> {
    let refreshToken = this.localStorageService.getJWTRefreshToken();
    //console.log('previous-jwt-token-',this.localStorageService.getJWTToken());
    //console.log('previous-refresh-token-',refreshToken);
    const refreshjwtToken = {
      "refreshToken": refreshToken
    }
    return this.authService.refreshJWTToken(refreshjwtToken)
      .pipe(switchMap(response => {
        // console.log('refresh-token---new',response);
        this.localStorageService.setJWTRefreshToken(response.refreshToken);
        this.localStorageService.setJWTToken(response.accessToken);

        request = request.clone({ setHeaders: this.getAPIAuthenticationHeaders(response.accessToken) });
        return next.handle(request) as Observable<HttpEvent<any>>;
      }));
  }

  getAPIAuthenticationHeaders(token: any) {
    console.log('---', token);
    return {
      'authorization': 'Bearer ' + token,
    };
  }
}
