import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { version } from '../../constants/gas-monkey-constants';
import { AuthService } from '../../pages/auth/service/auth.service';
import { ChangePasswordComponent } from '../../pages/role-management/change-password/change-password.component';
import { LocalStorageService } from '../../pages/shared/service/local-storage-service';
import { NavData, navItems } from '../../_nav';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { notification_status } from '../../constants/notification-status';
import { UtilService } from '../../pages/shared/service/util.service';
import { NotificationService } from '../core/notification-service/notification.service';
import { OrderViewComponent } from '../../pages/order-management/order-view/order-view.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit,AfterViewInit{
  version = version;
  public sidebarMinimized = false;
  public navItems = navItems;
  userName:any;
  jwttoken: string;
  message:any;
  public notificationCount: number = 0;
  notifications = [];
  authorityName:any;
  audioPath ='https://notificationsounds.com/storage/sounds/file-sounds-881-look-this-is-what-i-was-talking-about.mp3';
  @ViewChild('audio',{static: false}) audio: ElementRef;
  @ViewChild('button') btn: ElementRef;

  constructor(private authService: AuthService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    public messaging: AngularFireMessaging,
    private utilService: UtilService,
    private notificationService: NotificationService,
    private router: Router){
      this.setNavData();

  }

  ngOnInit(): void {
    // this.requestPermission();
  }

  ngAfterViewInit() {
    this.requestPermission();
  }

  requestPermission() {
    this.messaging.requestToken.subscribe(token => {
      console.log('Permission granted!')
      console.log(token)
      this.listen()
    }
      , error => {
        //alert("Please allow notifications");
       // console.error(error)
        this.utilService.showAlertSnackBarMessage(
          "Please allow notifications.",
          this.utilService.TYPE_MESSAGE.SUCCESS_TYPE
        );
      })
  }

  listen() {
    this.messaging.messages.subscribe(message => {
      console.log(message);
      if (message['notification']['body']) {
        this.notificationCount++;
        //notification sound
        this.audio.nativeElement.play();
        this.btn.nativeElement.click();
        this.audio.nativeElement.play();
        // --
      }

      this.notifications.unshift(message['notification']['body']);
    });
  }

  checkAuthorities(navigationItems: any, rolesArray: string[]): NavData[] {
    return navigationItems.filter(navItem => {
      let canSeeNavMenu = true;
      // console.log('-----check-Authorities--',navItem.attributes);
      if (navItem.attributes) {
        if (navItem.attributes.authorities) {
          canSeeNavMenu = navItem.attributes.authorities.some(auth => rolesArray.includes(auth));
        }
        if (navItem.attributes.restricted) {
          canSeeNavMenu = canSeeNavMenu && navItem.attributes.restricted.every(rest => !rolesArray.includes(rest));
        }
      }
      if (canSeeNavMenu && navItem.children) {
        navItem.children = this.checkAuthorities(navItem.children, rolesArray);
      }
      return canSeeNavMenu;
    });
  }

  setNavData() {
    this.navItems = [];
    this.jwttoken = this.localStorageService.getJWTToken();
    let jwtData = this.jwttoken.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    // console.log('jwt-data--',decodedJwtData);
    this.userName = decodedJwtData['username'];
    this.localStorageService.setUserName(decodedJwtData['username']);
    this.localStorageService.setUserId(decodedJwtData['userId']);
    this.localStorageService.setAuthorityName(decodedJwtData['authorities']);
    this.localStorageService.setRoleName(decodedJwtData['accessMenus']);
    // console.log('accessMenu--',decodedJwtData['accessMenus']);
    let roleArray = [];
    decodedJwtData['accessMenus'].forEach(el =>{
      roleArray.push(el.menu);
    });
    this.authorityName = this.localStorageService.getAuthorityName();
      if (decodedJwtData['authorities'] == 'SUPER_ADMIN') {

        this.navItems = this.checkAuthorities( navItems, [decodedJwtData['authorities']]);
      }else if (roleArray) {
        this.navItems = this.checkAuthorities( navItems,roleArray);
      }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  dashboard(){
    this.router.navigate(['/pages/dashboard']);
  }

  ResetPassword(){
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '900px',
      height: '500px',
      data: {
         title:'reset-password'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  logout(){
    this.dialog.closeAll();
    this.authService.logout();

  }

  clickNtfBar() {
    //this.clicked = true;
    // console.log("clicked notification-----");
    // this.audio.nativeElement.pause();
    this.getNotifications();
    this.notificationCount = 0;
  }

  goToUrl(ntf: any) {
    console.log("***",ntf);
    this.notificationService.putNotificationListData(ntf.id).subscribe(result => {
      // console.log('Response***',result);
    });
    // orderId
    this.sendRowDataForViewOrder(ntf.orderId);

    // if (ntf.url) {
    //   window.location.replace(ntf.url);
    // }
  }

  sendRowDataForViewOrder(orderId){
    console.log(orderId);
    const dialogRef = this.dialog.open(OrderViewComponent, {
      disableClose: true,
        width: '700px',
        height: '700px',
      data: {
        title: 'View',
        obj: {
           id: orderId
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('order view closed');
    });
  }

  getNotifications(){
    // console.log("get notification list");
    this.notificationService.getNotificationListData().subscribe(result => {
      // console.log('Response***',result);
      if (result != null) {
        this.notifications = result;
      }
    });
  }

  setPointer(ntf: any): string {
    return ntf.content ? "pointer" : "unset";
  }

  setBackground(ntf: any): string {
    return ntf.isSeen !== notification_status.SEEN
      ? "rgb(200, 255, 188)"
      : "white";
  }

}
