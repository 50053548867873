import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { ApiEndpoints } from '../../../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class LandingService {


  HTTPOptions:Object = {
    observe: 'response',
    responseType: 'text'
 }


  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  constructor(private http: HttpClient) { }

  getLandingProductListTableData(): Observable<any> {
    let url = this.apiEndpoints.LANDING_PAGE_PRODUCT_URL;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }
}
