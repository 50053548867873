import {Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { icon, LatLng, latLng, Map, marker, point, polyline, tileLayer } from 'leaflet';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { UtilService } from '../../shared/service/util.service';

@Component({
  selector: 'app-customer-select-lat-long-map',
  templateUrl: './customer-select-lat-long-map.component.html',
  styleUrls: ['./customer-select-lat-long-map.component.scss']
})
export class CustomerSelectLatLongMapComponent implements OnInit {
  map: any;
  route:any;
  options:any;
  manOneMarker:any;
  manTwoMarker:any;
  orderId:any;
  cnt:any;
  initialLatLong:any = [
    23.823201, 90.3779113
    // 23.86615060342202, 90.39221187870722
  ];
  destinationLatLong:any = [
    23.8226716,90.3933636
    // 23.875541390521143, 90.39609099608218
  ];
  routeDirection:any[];
  responseRouteData:any=[
    [23.823201,90.3779113],
  ];

  thanaBoundary = [];

  streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    detectRetina: true,
    // attribution: '&amp;copy; &lt;a href="https://www.openstreetmap.org/copyright"&gt;OpenStreetMap&lt;/a&gt; contributors'
  });

  provider = new OpenStreetMapProvider();

  constructor( public dialogRef: MatDialogRef<CustomerSelectLatLongMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilService: UtilService,
    ){

      console.log('order-map-info--',this.data);
    }

  ngOnInit() {
    this.cnt = 1;
    //this.routeDirection = this.responseRouteData;
    // this.getManOneMarker(this.initialLatLong);
    // this.getManTwoMarker(this.destinationLatLong);
    this.route = polyline(this.responseRouteData);
    // this.route = polygon(this.responseRouteData);
    this.options = {
      layers: [ this.streetMaps, this.route],
      zoom: 14,
      center: latLng(this.initialLatLong)
    };
 }
  // partner
  getManTwoMarker(initialLatLong){
    this.manTwoMarker = marker(initialLatLong, {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'assets/img/images/icon-maker-2.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });
  }

  //house
  getManOneMarker(destinationLatLong){
    this.manOneMarker = marker(destinationLatLong, {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'assets/img/images/icon-maker-1.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });
  }


  onMapReady(map: Map) {

    let searchControl  =GeoSearchControl({
      style: 'bar',
      provider: this.provider,
    })
    searchControl.addTo(map);

    map.on('geosearch/showlocation', (e)=>{
      this.responseRouteData = [];

       console.log('geolocation--',e);
    });

    map.fitBounds(this.route.getBounds(), {
      padding: point(24, 24),
      maxZoom: 25,
      animate: true
    }).on('click',(e)=>{
      console.log('map--',e);
      // console.log('map-lat--',e.latlng['lat']);
      // console.log('map-long--',e.latlng['lng']);

      let obj = {
        "slNo": this.cnt++,
        "latitude":  e['latlng']['lat'],
        "longitude": e['latlng']['lng']
      };

      if(this.thanaBoundary.length == 0){
        this.thanaBoundary.push(obj);
        this.utilService.showSnackBarMessage("GPS Address Added",this.utilService.TYPE_MESSAGE.SUCCESS_TYPE);
      }
      this.dialogRef.close(this.thanaBoundary);

      /*
      let arr = [];
      arr.push(e['latlng']['lat']);
      arr.push(e['latlng']['lng']);

      this.responseRouteData.push(arr);
      var firstpolyline = new Polyline(this.responseRouteData, {
          color: 'red',
          weight: 3,
          opacity: 0.5,
          smoothFactor: 1
      });
      firstpolyline.addTo(map);
      */

    });
  }

  onNoClick(): void {
    console.log('on-close-::',this.thanaBoundary);
    this.dialogRef.close(this.thanaBoundary);
  }
}
