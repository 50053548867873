import { MasterButtonService } from '../master-service/master-button-service';
import { MasterListService } from '../master-service/master-list-service';

export class ServiceListControlWrapper<S extends MasterListService, D extends MasterButtonService> {
  data: any[];
  isPaginated: boolean;
  totalDataSize: number;
  pageInfo: { pageSize: number, pageIndex: number };
  sortInfo: { active: string, sortDirection: string };
  listService:S;
  buttonService:D;

  constructor(listService: S, data: any[], buttonService?: D, totalDataSize?: number, pageInfo?: { pageSize: number, pageIndex: number }, sortInfo?: { active: string, sortDirection: string }, isPaginated: boolean = true) {
    console.log(data)
    this.listService = listService;
    this.buttonService = buttonService;
    this.data = data;
    this.isPaginated = isPaginated;
    this.totalDataSize = totalDataSize;
    this.pageInfo = pageInfo;
    this.sortInfo = sortInfo;
  }
}
