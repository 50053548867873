import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {

  options: string[] = [];

  form!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SearchDialogComponent>,
    private fb: FormBuilder,) { }

  ngOnInit(): void {
    console.log('==searchdata====', this.data.obj)
    this.options=this.data.obj

    this.form = this.fb.group({
      searchKey: [''],
    });

  }

  close() {

    this.dialogRef.close();

  }


}
